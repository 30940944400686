import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled from 'styled-components'

import { StyledDropDownContent } from '../DropDown/styles'

export const StyledLabel = styled.label.attrs(
  (props: { icon: { src: string }; hasError?: boolean; required?: boolean }) => props
)`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.redJEM : theme.colors.tealRobots)};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  position: relative;
  width: 100%;
  > input {
    border: 1px solid
      ${({ theme, hasError }) => (hasError ? theme.colors.redJEM : theme.colors.grayNes)};
    background: ${({ theme, hasError }) =>
      hasError ? theme.colors.redSurprise : theme.colors.grayWii};
    color: ${({ theme, hasError }) => (hasError ? theme.colors.redJEM : 'inherit')};
  }

  ${(props) =>
    props.icon?.src &&
    `
  ::before {
    background-image: url('${props.icon.src}');
    background-position-x: 1px;
    background-position-y: 1px;
    background-repeat: no-repeat;
    background-size: contain;
    content: ' ';
    height: 20px;
    left: 18px;
    position: absolute;
    top: 48px;
    width: 20px;
  }
  `}

  ${({ required, theme }) =>
    required &&
    `
    > span {
      ::after {        
        content: '*';
        color: ${theme.colors.redJEM};
        margin-left: 5px;
      }
    }
  `}
`

export const StyledErrorLabel = styled.span`
  margin-top: 10px;
  padding: 5px;
  background: ${({ theme }) => theme.colors.redSurprise};
  box-shadow: 0px 0px 8px rgba(149, 145, 142, 0.3);
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 12px;
`

export const StyledCountryCode = styled.div`
  position: absolute;
  left: 110px;
  width: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayNickelArcade};

  ${mediaQuery(
    BREAKPOINTS.SMALL,
    `
      font-size: 14px;
      left: 100px;
    `
  )}
`

export const StyledPhoneInput = styled.div.attrs(
  (props: { hasError?: boolean; country?: string }) => props
)`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

  & .dropdown {
    flex: 0 0 90px;
    position: initial;

    & ${StyledDropDownContent} {
      padding: 16px 6px;
      > span {
        font-size: 0;
        white-space: nowrap;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(${({ country }) =>
          country
            ? `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`
            : ''});
      }
    }
  }

  & input {
    flex: 1;
    border: 1px solid
      ${({ theme, hasError }) =>
        hasError ? theme.colors.redStrawberryShortcake : theme.colors.grayNes};
    background: ${({ theme, hasError }) =>
      hasError ? theme.colors.redSurprise : theme.colors.grayWii};
    padding-left: 60px;
    align-items: center;
    border-radius: 10px;
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.redPowerRangers : theme.colors.tealDollhouse};
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 500;
    height: 56px;
    margin: 10px 0 0;
    margin-bottom: 10px;

    &:focus-within {
      border: 1px solid
        ${({ theme, hasError }) =>
          hasError ? theme.colors.redStrawberryShortcake : theme.colors.tealPowerglove};
      outline: none;
    }

    &:hover {
      border: 1px solid
        ${({ theme, hasError }) =>
          hasError ? theme.colors.redStrawberryShortcake : theme.colors.tealPowerglove};
    }

    ${mediaQuery(
      BREAKPOINTS.SMALL,
      `
        padding-left: 45px;
        width: 100%;
      `
    )}
  }
`
